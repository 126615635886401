// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-shadow {
    box-shadow: 0px 10px 30px -14px rgba(0,0,0,0.74)
}`, "",{"version":3,"sources":["webpack://./src/css/about.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":[".box-shadow {\n    box-shadow: 0px 10px 30px -14px rgba(0,0,0,0.74)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
